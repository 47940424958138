<template>
  <div id="outWarehouse">
    <div class="filter-panel">
      <input
          v-model="searchTerm.itemName"
          class="cs-input"
          placeholder="搜索物品名称"
          style="width:280px"
          type="text"
      />
      <CSSelect
          style="margin-right:20px; width:146px"
      >
        <select v-model="searchTerm.itemState">
          <option selected value="">
            物品状态不限
          </option>
          <option
              v-for="itemState in itemStateList"
              :key="itemState.id"
              :value="itemState.id"
          >
            {{ itemState.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect
          style="margin-right:20px; width:146px"
      >
        <select v-model="searchTerm.itemType">
          <option selected value="">
            全部物品类型
          </option>
          <option
              v-for="itemType in itemTypeList"
              :key="itemType.id"
              :value="itemType.id"
          >
            {{ itemType.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect
          style="margin-right:20px;"
      >
        <select v-model="searchTerm.supplier" style="width:146px;">
          <option selected value="">
            全部供应商
          </option>
          <option
              v-for="supplier in supplierList"
              :key="supplier.id"
              :value="supplier.id"
          >
            {{ supplier.companyName }}
          </option>
        </select>
      </CSSelect>
      <CSSelect
          style="margin-right: 30px; width:146px"
      >
        <select v-model="searchTerm.warnState">
          <option selected value="">
            预警状态不限
          </option>
          <option
              v-for="warnState in warnStateList"
              :key="warnState.id"
              :value="warnState.id"
          >
            {{ warnState.name }}
          </option>
        </select>
      </CSSelect>
      <button
          class="btn btn-primary btn-sm"
          type="button"
          @click="getItem()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary btn-sm sticky-right"
                type="button"
                @click="addWarehouseItem()"
            >
              <svg
                  aria-hidden="true"
                  class="icon"
              >
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              物品
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>物品名称</th>
            <th>物品状态</th>
            <th>当前剩余数量</th>
            <th>累计入库数量</th>
            <th>借出数量</th>
            <th>出库不归还数量</th>
            <th>预警数量</th>
            <th>创建人</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr
              v-for="item in itemList"
              :key="item.id"
              :data-id="item.id"
          >
            <td class="date-td">{{ item.createTime }}</td>
            <td>
              <span v-if="item.state === 3">-</span>
              <span
                  v-else
                  class="allow-click"
                  @click="itemInterface(item)"
              >
                                {{ item.name }}
                            </span>
            </td>
            <td>{{ item.stateName }}</td>

            <td style="position:relative">
                            <span v-show="item.isWarning === 1" style="
                                position:absolute;
                                padding:2px 5px;
                                background-color:#FFE6E6;
                                color:#FF5A5A;
                                border-radius:10px;
                                transform:scale(0.8);
                                font-size:12px;
                                top:5px;
                                right:30%;
                            ">过低</span>
              {{ item.currentCount }}
            </td>
            <td>{{ item.storeCount }}</td>
            <td>{{ item.loanCount }}</td>
            <td>{{ item.deliverCount }}</td>
            <td>
              <span v-if="item.state === 3">-</span>
              <span v-else>{{ item.warningCount }}</span>
            </td>
            <td>{{ item.createUser }}【{{ item.jobTitle }}】</td>
            <td>
                            <span
                                v-if="item.state === 1 && item.comment"
                                class="allow-click"
                                @click="noteInterface(item)"
                            >查看</span>
              <span v-else>-</span>
            </td>
            <td>
              <span v-if="item.state === 3">-</span>
              <div v-else class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width:114px;">
                  <li @click="changeWarnQuantity(item)">
                    <a style="width:100%">设置预警数量</a>
                  </li>
                  <li @click="confirmTargetId(item)">
                    <a style="width:100%">修改存放位置</a>
                  </li>
                  <li v-show="item.state === 1" @click="isForrbiden('forrbiden',item)">
                    <a style="width:100%">禁用</a>
                  </li>
                  <li v-show="item.state === 2" @click="isForrbiden('allow',item)">
                    <a style="width:100%">启用</a>
                  </li>
                  <li v-show="item.state === 2" @click="isForrbiden('drop',item)">
                    <a style="width:100%">作废</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination
          component="pagination"
          name="pagination"
      ></Pagination>
      <!-- 查看物品信息 -->
    </div>
    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-visible="itemInfo.visible"
        class="tenant-detail-dialog"
        dialog-cancel-btn-text="关闭"
        dialog-header-class="alert-bg"
        dialog-title="物品信息"
        dialog-width="540px"
        @onClose="itemInfo.visible = false"
    >
      <template
          v-slot:dialog-content
      >
        <div style="max-height: 550px; overflow-y: auto; font-size: 24px; padding: 30px;">
          <div class="preview-tenant">
            <p class="field">
              <span class="field-label">物品名称</span>
              <span class="field-content">
                                    {{ itemInfo.itemDetail.name }}
                                </span>
            </p>
            <p class="field">
              <span class="field-label">品牌型号</span>
              <span class="field-content">
                                    {{ itemInfo.itemDetail.model }}
                                </span>
            </p>
            <p class="field">
              <span class="field-label">物品类型</span>
              <span class="field-content">
                                    {{ itemInfo.itemDetail.typeName }}
                                </span>
            </p>
            <p class="field">
              <span class="field-label">存放位置</span>
              <span class="field-content">
                                    {{ itemInfo.itemDetail.specificLocation }}
                                </span>
            </p>
            <p class="field">
              <span class="field-label">供应商</span>
              <span class="field-content">
                                    {{ itemInfo.itemDetail.companyName }}</span>
            </p>
            <p class="field">
              <span class="field-label">对接人</span>
              <span class="field-content">{{ itemInfo.itemDetail.dockingPeople }}</span>
            </p>
            <p class="field">
              <span class="field-label">对接人手机号</span>
              <span class="field-content">{{ itemInfo.itemDetail.dockingPeoplePhone }}</span>
            </p>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 查看备注 -->
    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-visible="noteInfo.visible"
        class="tenant-detail-dialog"
        dialog-cancel-btn-text="关闭"
        dialog-header-class="alert-bg"
        dialog-title="备注"
        dialog-width="450px"
        style="text-align:center"
        @onClose="noteInfo.visible = false"
    >
      <template>
        <div
            slot="dialog-content"
            style="padding: 20px 30px; font-size: 24px;"
        >
          <template>
            <div>
              {{ noteInfo.noteDetail.comment }}
            </div>
          </template>
        </div>
      </template>
    </CSDialog>
    <!-- 添加物品 -->
    <Itemmanage></Itemmanage>
    <!-- 修改存放位置 -->
    <CSDialog
        :dialog-visible="newPositionInfo.visible"
        class="tenant-detail-dialog"
        dialog-title="修改存放位置"
        dialog-width="600px"
        @onClose="newPositionInfo.visible = false"
        @onConfirm="doneChangeItemPosition()"
    >
      <template
          v-slot:dialog-content
      >
        <div style="max-height:261px;font-size:24px;padding:30px">
          <div class="preview-tenant">
            <!-- newPositionInfo.target.specificLocation -->
            <div style="display:flex;align-items:center;margin-top:20px;margin-bottom:10px;">
              <label
                  class="field-label"
                  style="
                                    width:96px;
                                    display:inline-block;
                                    margin-top:1px;
                                    text-align:right;
                                    vertical-align:middle;
                                    margin-bottom:3px;
                                ">存放位置</label>
              <ChooseLocation :mission="identify" :placeholder="newPositionInfo.target.specificLocation"
                              style="--readonly-input-width: 250px"
                              @changeLocation="confirmPositionId"></ChooseLocation>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 修改预警数量 -->
    <CSDialog
        :dialog-visible="newWarnQuantityInfo.visible"
        class="tenant-detail-dialog"
        dialog-title="设置预警数量"
        dialog-width="520px"
        @onClose="newWarnQuantityInfo.visible = false"
        @onConfirm="doneChangeWarnQuantity()"
    >
      <template
          v-slot:dialog-content
      >
        <div style="max-height:261px;overflow-y:auto;font-size:24px;padding:30px">
          <div class="preview-tenant">
            <p class="field">
              <span class="field-label" style="margin-top:2px;width:96px">预警数量</span>
              <CSSelect height="40px" iWidth="36px" style="width:250px;">
                <select v-model="newWarnQuantityInfo.newWarnQuantity" @change="jugeWarn()">
                  <option selected value="">请选择</option>
                  <option
                      v-for="newWarnQuantity in newWarnQuantityInfo.newWarnQuantityList"
                      :key="newWarnQuantity.id"
                      :value="newWarnQuantity.id"
                  >{{ newWarnQuantity.name }}
                  </option>
                </select>
              </CSSelect>
            </p>
            <span v-show="isWarn"
                  style="
                                    margin-left:60px;
                                    font-size:20px;
                                    color:#999999;
                                    line-height:28px;"
            >
                            <svg
                                aria-hidden="true"
                                class="icon"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            当前剩余数量低于预警数量。
                        </span>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 是否作废 -->
    <CSDialog
        :dialog-confirm-btn-text="forrbidenTxt.confirmBtnTxt"
        :dialog-title="forrbidenTxt.txt"
        :dialog-visible="forrbidenTxt.visible"
        class="tenant-detail-dialog"
        dialog-width="430px"
        @onClose="forrbidenTxt.visible = false"
        @onConfirm="changeState()"
    >
      <template
          v-slot:dialog-content
      >
        <div style="font-size:24px;text-align:center;margin:30px;">确定{{ forrbidenTxt.txt }}?</div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import Itemmanage from "@/components/warehouse/itemManage";
import ChooseLocation from "@/components/ChooseLocation";
import {STORAGE_KEY} from "@/constant";

import {
  querySimilarLocationUrl,
  warehouseAddItemUrl,
  warehouseItemUrl,
  warehouseStateUrl,
  warehouseSupplierUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  components: {
    CSTable,
    CSDialog,
    CSSelect,
    Pagination,
    ChooseLocation,
    Itemmanage,
  },
  data() {
    return {
      filterHeight: 0,
      identify: "",
      isWarn: false,
      isConfirm: false,
      regionId: this.$vc.getCurrentRegion().communityId,
      searchTerm: {
        itemName: "",
        itemState: "",
        itemType: "",
        supplier: "",
        warnState: "",
      },
      itemStateList: [
        {id: 1, name: "启用"},
        {id: 2, name: "禁用"},
        {id: 3, name: "废除"},
      ],
      itemTypeList: [
        {id: 1, name: "零件"},
        {id: 2, name: "消耗品"},
        {id: 3, name: "工具"},
        {id: 4, name: "工服"},
        {id: 5, name: "其它"}
      ],
      supplierList: [],
      warnStateList: [
        {id: 0, name: "数量正常"},
        {id: 1, name: "数量过低"}
      ],
      itemList: [],
      itemInfo: {
        visible: false,
        itemDetail: {}
      },
      noteInfo: {
        visible: false,
        noteDetail: {}
      },
      // 修改物品存放位置
      newPositionInfo: {
        visible: false,
        target: {},
        positionInfo: {},
        newPosition: "",
        itemPositionList: []
      },
      // 修改物品警报数量
      newWarnQuantityInfo: {
        visible: false,
        target: {},
        newWarnQuantity: "",
        newWarnQuantityList: [],
      },
      forrbidenTxt: {
        visible: false,
        txt: "启用",
      },
      newItemInfo: {},
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    window.addEventListener("keydown", this.getItemDown);
    this.$vc.on(this.$route.path, "itemManage", "itemManageDone", (data) => {
      this.newItemInfo = data;
      this.addNewItem();
    })
    for (var i = 1; i <= 100; i++) {
      this.newWarnQuantityInfo.newWarnQuantityList.push({id: i, name: i});
    }
    this.getItem();
    this.getSupplier();
    this.getPosition();
  },
  deactivated() {
    window.removeEventListener("keydown", this.getItemDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getItemDown);
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
      this.pageParams.pageNo = currentPage;
      this.getItem(currentPage);
    })
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  methods: {
    getItemDown(e) {
      if (e.keyCode == 13) {
        this.getItem();
      }
    },
    jugeWarn() {
      var isWarn = this.newWarnQuantityInfo.newWarnQuantityList;
      var val = this.newWarnQuantityInfo.newWarnQuantity;
      var currentCount = this.newWarnQuantityInfo.target.currentCount;
      if (currentCount === 0) {
        this.isWarn = false;
      }
      for (var i = 0; i < isWarn.length; i++) {
        if (val === isWarn[i].id) {
          if (currentCount <= isWarn[i].name) {
            this.isWarn = true;
            return;
          }
          this.isWarn = false;
          break;
        }
      }
    },
    getPosition() {
      this.$fly.post(querySimilarLocationUrl, {
        regionCode: this.$vc.getCurrentRegion().communityId,
        id: "",
        specificLocation: ""
      })
          .then(res => {
            this.newPositionInfo.itemPositionList = res.data.datas || res.data;
          })
    },
    getSupplier() {
      this.$fly.post(warehouseSupplierUrl, {
        regionCode: this.$vc.getCurrentRegion().communityId,
        state: 1,
      })
          .then(res => {
            this.supplierList = res.data.datas || res.data;
          })
    },
    getItem(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(warehouseItemUrl, {
        regionCode: this.$vc.getCurrentRegion().communityId,
        search: this.searchTerm.itemName,
        state: this.searchTerm.itemState,
        type: this.searchTerm.itemType,
        supplierId: this.searchTerm.supplier,
        isWarning: this.searchTerm.warnState,
        pageNo,
        pageSize,
      })
          .then(res => {
            var dataArr = res.data.datas;
            var stateArr = this.itemStateList;
            var toolArr = this.itemTypeList;
            for (var i = 0; i < dataArr.length; i++) {
              for (var j = 0; j < stateArr.length; j++) {
                if (dataArr[i].state === stateArr[j].id) {
                  dataArr[i].stateName = stateArr[j].name;
                  break;
                }
              }
              for (var j = 0; j < toolArr.length; j++) {
                if (dataArr[i].type === toolArr[j].id) {
                  dataArr[i].typeName = toolArr[j].name;
                  break;
                }
              }
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              })
            }
            this.itemList = res.data.datas;
          })
    },
    addWarehouseItem() {
      this.$vc.emit(this.$route.path, "itemManage", "addItem", {
        boolean: true,
        supplierList: this.supplierList,
        itemTypeList: this.itemTypeList,
      });
    },
    addNewItem() {
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: "<span>提交后将无法修改信息,确认提交吗?</span>",
        onConfirm: () => {
          this.$fly.post(warehouseAddItemUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            name: this.newItemInfo.itemName,
            model: this.newItemInfo.itemCode,
            type: +this.newItemInfo.itemType,
            locationId: +this.newItemInfo.itemPosition,
            supplierId: +this.newItemInfo.itemSupplier,
            comment: this.newItemInfo.itemNote,
          })
              .then(res => {
                if (res.code !== 0) {
                  return;
                }
                this.getItem();
                this.$CSDialog.instance.closeDialog();
              })
        }
      })
    },
    itemInterface(target) {
      this.itemInfo = {
        visible: true,
        itemDetail: target,
      }
    },
    noteInterface(target) {
      this.noteInfo = {
        visible: true,
        noteDetail: target,
      }
    },
    confirmTargetId(target) {
      this.newPositionInfo.visible = true;
      this.newPositionInfo.target = target;
    },
    confirmPositionId(data) {
      this.newPositionInfo.newPosition = data.id;
    },
    doneChangeItemPosition() {
      var newPositionId = this.newPositionInfo.newPosition;
      var positionList = this.newPositionInfo.itemPositionList;
      for (var i = 0; i < positionList.length; i++) {
        if (newPositionId === positionList[i].id) {
          this.newPositionInfo.positionInfo = positionList[i]
        }
      }
      this.changeItemState(this.newPositionInfo.target, 'position')
      this.newPositionInfo.visible = false;
    },
    changeWarnQuantity(target) {
      this.newWarnQuantityInfo.visible = true;
      this.newWarnQuantityInfo.target = target;
    },
    doneChangeWarnQuantity() {
      var warnId = this.newWarnQuantityInfo.newWarnQuantity;
      var quantityId = this.newWarnQuantityInfo.newWarnQuantityList;
      for (var i = 0; i < quantityId.length; i++) {
        if (warnId === quantityId[i].id) {
          this.newWarnQuantityInfo.target.warningCount = quantityId[i].name;
          break;
        }
      }
      this.changeItemState(this.newWarnQuantityInfo.target, 'warnQuantity');
      this.newWarnQuantityInfo.visible = false;
    },
    changeItemState(target, act) {
      var body = {
        regionCode: this.$vc.getCurrentRegion().communityId,
        id: target.id
      }
      if (act === 'position') {
        body.locationId = this.newPositionInfo.positionInfo.id;
      } else if (act === 'warnQuantity') {
        body.warningCount = target.warningCount;
      } else {
        body.state = target.stateCode;
      }
      this.$fly.post(warehouseStateUrl,
          body,
      )
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.getItem();
            this.newWarnQuantityInfo.newWarnQuantity = "";
            this.newPositionInfo.newPosition = "";
          })
    },
    isForrbiden(state, target) {
      let id = target.id;
      if (state === 'forrbiden') {
        this.forrbidenTxt = {
          id,
          stateCode: 2,
          txt: "禁用",
        }
      } else if (state === 'allow') {
        this.forrbidenTxt = {
          id,
          stateCode: 1,
          txt: "启用",
        }
      } else if (state === 'drop') {
        this.forrbidenTxt = {
          id,
          stateCode: 3,
          txt: "作废",
        }
      }
      this.changeState(this.forrbidenTxt);
    },
    changeState(target) {
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: "<span>确定" + this.forrbidenTxt.txt + "吗?</span>",
        onConfirm: () => {
          this.changeItemState(target);
          this.$CSDialog.instance.closeDialog();
        }
      })
    }
  },
  destroyed() {
    sessionStorage.setItem(STORAGE_KEY.WAREHOUSE_TABID, 0);
  }
}
</script>

<style lang="stylus">
.tenant-detail-dialog
  .el-dialog__header
    display none

  .preview-tenant
    color #000

    .field
      &-label
        width 200px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top

      &-content
        display inline-block
        max-width calc(100% - 240px)

  .el-divider__text.is-left
    font-size 24px
</style>
