<template>
  <CSDialog
      :dialog-visible="newItemInfo"
      class="tenant-detail-dialog"
      dialog-title="添加物品"
      @onClose="newItemInfo = false"
      @onConfirm="jugement()"
  >
    <template v-slot:dialog-content>
      <div style="max-height: 788px; font-size: 24px; padding: 30px">
        <div class="preview-tenant">
          <p class="field">
            <span class="field-label" style="margin-top: 1px">物品名称</span>
            <input
                v-model="addItemInfo.itemName"
                placeholder="限10个字"
                style="
                width: 380px;
                height: 40px;
                padding: 0px 10px;
                border: 1px solid #979797;
                border-radius: 4px;
              "
                type="text"
            />
          </p>
          <p class="field">
            <span class="field-label" style="margin-top: 1px">品牌型号</span>
            <input
                v-model="addItemInfo.itemCode"
                placeholder="限30个字"
                style="
                width: 380px;
                height: 40px;
                padding: 0px 10px;
                border: 1px solid #979797;
                border-radius: 4px;
              "
                type="text"
            />
          </p>
          <p class="field">
            <span class="field-label" style="margin-top: 1px">物品类型</span>
            <CSSelect height="40px" iWidth="36px">
              <select
                  v-model="addItemInfo.itemType"
                  style="width: 220px; padding: 0px 5px;color: #999999"
              >
                <option selected value="">请选择</option>
                <option
                    v-for="itemType in itemTypeList"
                    :key="itemType.id"
                    :value="itemType.id"
                    placeholder="请选择"
                >
                  {{ itemType.name }}
                </option>
              </select>
            </CSSelect>
          </p>
          <div
              style="
              display: flex;
              align-items: center;
              margin-top: 1px;
              margin-bottom: 10px;
            "
          >
            <label
                class="field-label"
                style="
                width: 200px;
                display: inline-block;
                margin-top: 1px;
                text-align: right;
                vertical-align: middle;
              "
            >存放位置</label
            >
            <ChooseLocation
                :mission="positionIdentify"
                style="--readonly-input-width: 194px"
                @changeLocation="selectPosition"
            ></ChooseLocation>
            <!-- <CSSelect iWidth="36px" height="40px">
                            <select style="width:220px;padding:0px 5px;" v-model="addItemInfo.itemPosition">
                                <option selected value="">请选择</option>
                                <option
                                    v-for="itemPosition in itemPositionList"
                                    :value="itemPosition.id"
                                    :key="itemPosition.id"
                                    placeholder="请选择"
                                >
                                    {{itemPosition.buildingName ? `${itemPosition.buildingName}` : ''}}{{itemPosition.floor ? `${itemPosition.floor}层` : ''}}{{itemPosition.specificLocation}}
                                </option>
                            </select>
                        </CSSelect> -->
          </div>
          <div
              style="
              display: flex;
              align-items: center;
              margin-top: 1px;
              margin-bottom: 10px;
            "
          >
            <label
                class="field-label"
                style="
                width: 200px;
                display: inline-block;
                margin-top: 1px;
                text-align: right;
                vertical-align: middle;
              "
            >供应商</label
            >
            <ChooseLocation
                :mission="supplierIdentify"
                style="--readonly-input-width: 194px"
                @addSupplier="selectSupplier"
            ></ChooseLocation>
          </div>
          <p class="field">
            <span class="field-label">备注</span>
            <textarea
                v-model="addItemInfo.itemNote"
                placeholder="限200个字，非必填"
                style="
                width: 580px;
                height: 180px;
                padding: 10px;
                resize: none;
                border: 0px;
                border-radius: 10px;
                background-color: #f0f0f0;
              "
            ></textarea>
          </p>
          <span
              style="
              margin-left: 240px;
              font-size: 20px;
              color: #999999;
              line-height: 28px;
            "
          >
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            确定添加后，将无法修改信息，请仔细认真检查所填信息。
          </span>
        </div>
      </div>
    </template>
  </CSDialog>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import ChooseLocation from "@/components/ChooseLocation";

import {querySimilarLocationUrl} from "@/requestUrl";

export default {
  components: {
    CSDialog,
    CSSelect,
    ChooseLocation,
  },
  data() {
    return {
      newItemInfo: false,
      positionIdentify: "",
      supplierIdentify: "itemManage",
      addItemInfo: {
        itemName: "",
        itemCode: "",
        itemType: "",
        itemPosition: "",
        itemSupplier: "",
        itemNote: "",
      },
      // 物品类型列表
      itemTypeList: [],
      // 供应商列表
      supplierList: [],
      itemPositionList: []
    };
  },
  created() {
    this.$vc.on(this.$route.path, "itemManage", "addItem", (data) => {
      (this.newItemInfo = data.boolean),
          (this.itemTypeList = data.itemTypeList),
          (this.supplierList = data.supplierList);
    });
    this.getPosition();
  },
  methods: {
    selectPosition(data) {
      this.addItemInfo.itemPosition = data.id;
    },
    selectSupplier(data) {
      this.addItemInfo.itemSupplier = data.id;
    },
    getPosition() {
      this.$fly
          .post(querySimilarLocationUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            id: "",
            specificLocation: "",
          })
          .then((res) => {
            this.itemPositionList = res.data.datas || res.data;
          });
    },
    jugement() {
      if (this.jugementItem()) {
        this.$vc.emit(
            this.$route.path,
            "itemManage",
            "itemManageDone",
            this.addItemInfo
        );
        this.addItemInfo = {
          itemName: "",
          itemCode: "",
          itemType: "",
          itemPosition: "",
          itemSupplier: "",
          itemNote: "",
        };
        this.newItemInfo = false;
      }
    },
    jugementItem() {
      return this.$vc.validate.validate(
          {
            addItem: this.addItemInfo,
          },
          {
            "addItem.itemName": [
              {
                limit: "required",
                param: "",
                errInfo: "请输入物品名称",
              },
              {
                limit: "maxin",
                param: "1,10",
                errInfo: "物品名称限10个字",
              },
            ],
            "addItem.itemCode": [
              {
                limit: "required",
                param: "",
                errInfo: "请输入品牌型号",
              },
              {
                limit: "maxin",
                param: "1,30",
                errInfo: "代码长度应为1-30",
              },
            ],
            "addItem.itemType": [
              {
                limit: "required",
                param: "",
                errInfo: "请选择物品类型",
              },
            ],
            "addItem.itemPosition": [
              {
                limit: "required",
                param: "",
                errInfo: "请选择存放位置",
              },
            ],
            "addItem.itemSupplier": [
              {
                limit: "required",
                param: "",
                errInfo: "请选择物品供应商",
              },
            ],
          }
      );
    },
  },
};
</script>

<style>
</style>
